import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Link from 'gatsby-link'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero/Hero'
import Expandable from '../../components/Expandable/Expandable'
import styles from './pricing.module.scss'
import { newlines } from '../../utils'
import ArrowRight from '../../assets/arrow-right.svg'

export const PricingPageTemplate = ({
  seo,
  title,
  subtitle,
  promises = {},
  checklist = {},
  faq,
  location,
}) => (
  <Layout seo={seo} location={location}>
    <div className={cx('homepage')}>
      <Hero classNames={['mainHero']}>
        <div className={cx(styles.heroContent)}>
          <div className={cx(styles.header)}>
            <h1 className="display-2">{newlines(title)}</h1>
            {subtitle && <h4>{subtitle}</h4>}
          </div>
          <div className={cx(styles.promises)}>
            <h5>{promises.title}</h5>
            <ul className={cx(styles.checklist)}>
              {promises.items.map(({ item }, i) => (
                <li key={`i${i}`}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </Hero>

      <section className={cx(styles.checklistBlock)}>
        <div className={cx('container')}>
          <h1 style={{ maxWidth: '400px', marginBottom: '60px' }}>
            {newlines(checklist.title)}
          </h1>
          <div className={cx(styles.checklists, 'inline-container', 'ic-3')}>
            {checklist.sections.map(section => (
              <div className={cx(styles.checklistSection)} key={section.title}>
                <h6>{section.title}</h6>
                <p>{section.subtitle}</p>
                <ul className={cx(styles.checklist)}>
                  {section.items.map(({ item }, i) => (
                    <li key={`i${i}`}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={cx(styles.faq)}>
        <div className={cx('container')}>
          <h1>Questions & Answers.</h1>
          <div className={cx(styles.faqs)}>
            {faq.map((qa, i) => (
              <Expandable
                title={qa.question}
                content={qa.answer}
                key={`qa${i}`}
              />
            ))}
          </div>
        </div>
      </section>

      <div className={cx('container', styles.demoCta, 'pt-0')}>
        <h2>
          Ready to try it?{' '}
          <a href="https://candidate.guide/walkthrough/" alt="Get Started">
            Learn More{' '}
            <span role="img" aria-label="go">
              👉
            </span>
          </a>
        </h2>
      </div>
    </div>
  </Layout>
)

PricingPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

PricingPageTemplate.defaultProps = {
  subtitle: null,
}

const PricingPage = ({ data, location }) => {
  const {
    markdownRemark: { frontmatter },
  } = data
  return <PricingPageTemplate location={location} {...frontmatter} />
}

PricingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
}

export default PricingPage

export const pricingPageQuery = graphql`
  query PricingPage {
    markdownRemark(frontmatter: { name: { eq: "pricing" } }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        subtitle
        promises {
          title
          items {
            item
          }
        }
        checklist {
          title
          sections {
            title
            subtitle
            items {
              item
            }
          }
        }
        faq {
          question
          answer
        }
      }
    }
  }
`
