import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './expandable.module.scss';

class Expandable extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.setContentHeight = this.setContentHeight.bind(this);
    this.contentRef = React.createRef();
    this.state = {
      expanded: false,
      contentHeight: 0,
    };
  }

  componentDidMount() {
    this.setContentHeight();
    window.addEventListener("resize", this.setContentHeight.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setContentHeight.bind(this));
  }

  setContentHeight() {
    const { scrollHeight } = this.contentRef.current || {};
    this.setState({ contentHeight: `${scrollHeight}px`});
  }

  toggle() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const {
      title,
      content,
    } = this.props;
    const {
      expanded,
      contentHeight
    } = this.state;

    return (
      <div className={cx(styles.expandable)}>
        <div className={cx(styles.title, { [styles.expanded]: expanded })} onClick={this.toggle}>
          {title}
        </div>
        <div
          ref={this.contentRef}
          className={cx(styles.content, { [styles.hidden]: !expanded })}
        >
          <p>{content}</p>
        </div>
      </div>
    );
  }
}

Expandable.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

Expandable.defaultProps = {
};

export default Expandable;
